import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchSongDetail } from "../store/actions/song";
import { ReactComponent as InfoIcon } from '../assets/info-icon.svg';
import { ReactComponent as MusicIcon } from '../assets/music-icon.svg';
import SongLyrics from "../components/SongLyrics";
import '../stylesheets/styles.css'
import AudioPlayer from "../components/AudioPlayer";
import YoutubePlayer from "../components/YoutubePlayer";
import { getUrlExtension } from "../utilities/helper";
import Popup from "reactjs-popup";
// import { ReactComponent as SettingsIcon } from '../assets/settings.svg';
import guitarIcon from '../assets/icon_guitar_chords.png'
import backIcon from '../assets/icon_home.png'
//import { Helmet } from "react-helmet";
import { Helmet } from "react-helmet-async";

const SongDetailPage = props => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [ displayChords, setDisplayChords ] = useState(true)

    const { songDetail } = useSelector(state => state.song)
    // const songId = location.state.songId
    const { songId } = useParams()
    const medias = songDetail?.media ?? []

    let name = ''
    if ((songDetail?.song_name !== null) && (songDetail?.song_name !== undefined) && (songDetail?.song_name !== '')) {
        name = songDetail?.song_name
    } else if ((songDetail?.song_firstline !== null) && (songDetail?.song_firstline !== undefined) && (songDetail?.song_firstline !== '')) {
        name = songDetail?.song_firstline
    }

    const pageTitle = `${songDetail?.song_number ?? ''} - ${name}`;
    const pageDescription = `Enjoy the song "${name}" (${songDetail?.song_number ?? ''}.)`;
    // document.title = `${songDetail?.song_number ?? ''} - ${name}` // handled by Helmet
    console.log('SongDetail PAge,', songDetail);


    useEffect(() => {
        dispatch(fetchSongDetail(songId))
        
    }, [dispatch, songId])

    // const handleMusicClick = () => {
    //     console.log('music click');
    // }
//{(i + 1) === songDetail?.related_songs.length ? '' : ','}

    const backIconClick = () => {
        navigate(-1)
    }

    const guitarIconClick = () => {
        console.log('Guitar clicked');
        setDisplayChords(!displayChords)
    }

    const BackIcon = <button className="back-icon-button" onClick={backIconClick}>
        <img className="back-icon" src={backIcon} alt='new'/>
    </button>

    const GuitarIcon = <button className="guitar-icon-button" onClick={guitarIconClick}>
        <img className="guitar-icon" src={guitarIcon} alt='new'/>
    </button>

    const RelatedSongs = (songDetail?.related_songs?.map((s, i) => (<h5 className="related-songs-link" key={`rsng${i}`} onClick={() => navigate(`/detail/${s.song_id}`)}>{s.song_number}{(i + 1) === songDetail?.related_songs.length ? '' : ','}</h5>)))

    const RelatedSongsComponent = RelatedSongs?.length > 0 ? (<div className="related-songs">&#127760;{RelatedSongs}</div> ) : <div />

    const RelatedTunes = (songDetail?.related_tunes?.map((s, i) => (<h5 className="related-songs-link" key={`rtns${i}`} onClick={() => navigate(`/detail/${s.song_id}`)}>{s.tune_name}{(i + 1) === songDetail?.related_tunes.length ? '' : ','}</h5>)))
    console.log('Relatedtunes:', );
    const RelatedTunesComponent = RelatedTunes?.length > 0 ? (<div className="related-songs">Related tunes: {RelatedTunes}</div> ) : <div />

    let text = '';
    if ((songDetail?.capo !== undefined) && (songDetail?.capo !== '') && (songDetail?.capo !== '0')) {
      text = `Capo ${songDetail?.capo}\u2013${songDetail?.key_with_capo}`;
    //   if (activeCapo) {
    //     text = songKey ?? '';
    //   }
    } else {
      if (songDetail?.song_key !== undefined) text = songDetail?.song_key;
    }

    const Capo = (<div className="capo-text"><p>{text}</p></div>)

    let mediaComponents = []
    for (const media of medias) {
        const mediaUrl = media.media_url
        console.log('MEDIA URL:', mediaUrl);
        console.log(getUrlExtension(mediaUrl));
        if (mediaUrl.includes('youtube.com')) {
            mediaComponents.push((<YoutubePlayer key={media.id} title={media.title} className="video-player" url={mediaUrl} />))
        } else if((getUrlExtension(mediaUrl) === 'mp3') || (getUrlExtension(mediaUrl) === 'm4a')) {
            if (media.allow_download === 1 ) {
                mediaComponents.push((<AudioPlayer key={media.id} title={media.title} className='audio-player' url={mediaUrl} />))
            }
        }
    }

    const SongInfo = (<Popup trigger={<div><InfoIcon className="info-icon" /></div>} modal>
        {close => (<div className='overlay'><button className="close" onClick={close}>
          &times;
        </button><div className='popup-content' dangerouslySetInnerHTML={{__html: songDetail?.song_info ?? ''}}></div></div>)}
    </Popup>)

    const MusicInfo = (<Popup trigger={<div><MusicIcon className="info-icon" /></div>} modal>
        {close => (<div className='overlay'><button className="close" onClick={close}>
            &times;
        </button><div className='music-popup-content'><img className="music-sheet" src={songDetail?.sheet_music_url ?? ''} alt='new'/></div></div>)}
    </Popup>)

    const Copyright = (<p className="copyright-text">{songDetail?.copyright_attribution}</p>)

    return songDetail ? (

		<>
			<Helmet>
				<title>{pageTitle}</title>
				<meta name="description" content={pageDescription} />
				<link rel="canonical" href={`https://songs.enjoyer.life/song/${songId}`} />

				{/* Open Graph Tags */}
				<meta property="og:title" content={pageTitle} />
				<meta property="og:description" content={pageDescription} />
				<meta property="og:type" content="article" />
				<meta property="og:url" content={`${window.location.origin}/song/${songId}`} />
				<meta property="og:image" content={songDetail?.media?.[0]?.thumbnail || `${window.location.origin}/LOGO.png`} />

				{/* Twitter Metadata */}
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:title" content={pageTitle} />
				<meta name="twitter:description" content={pageDescription} />
				<meta name="twitter:image" content={songDetail?.media?.[0]?.thumbnail || `${window.location.origin}/LOGO.png`}  />
			</Helmet>
			
			<div className="detail-body">
				
				{/* <h1>{`${songDetail?.song_number ?? ''} - ${name}`}</h1> */}
				<h1>{pageTitle}</h1>
				{BackIcon}
				{GuitarIcon}
				{RelatedSongsComponent}
				{RelatedTunesComponent}
				{displayChords ? Capo : <></>}
				<div className="song-text-body">
					<SongLyrics lyrics={songDetail?.song_text} showChords={displayChords} />
					<div className="other-buttons">
						{SongInfo}
						{MusicInfo}
					</div>
					<div className="medias">
						{mediaComponents.map(m => m)}
					</div>
				</div>
				{Copyright}
			</div>
		</>
	) : (
		<div>Loading...</div>
    );
};

export default SongDetailPage
