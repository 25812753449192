import React, { useState, useEffect } from "react";
import '../stylesheets/song_lyrics.scss'
import * as Constants from '../utilities/constants'

const SongLyrics = props => {

    const songLyrics = (props.lyrics ?? '').split('\\n').join('\n')//.replace('\\n', '\n')

    const [transpose, setTranspose] = useState(0)

    // console.log('SONG LYRICS:', songLyrics);

    const mod = (n, m) => {
        return ((n % m) + m) % m;
    }


    const changeKey = (step) => {
      const key = parseInt(transpose);
      setTranspose(key + step)
    }

  const upHalfStep = (e) => {
    changeKey(1);
  }

  const downHalfStep = (e) => {
    changeKey(-1);
  }

    const transposePresetKey = (e) => {
      if(Constants.regex.capo.test(songLyrics)) {
        const presetTranspose = songLyrics.match(Constants.regex.capo)[1];
        let newTranspose = 0;
        if(transpose !== presetTranspose) {
          newTranspose = presetTranspose;
        }
        this.setState({transpose: parseInt(newTranspose)});
        setTranspose(parseInt(newTranspose))
      }
    }

    const addTransposeListeners = () => {
      if(/\[/.test(songLyrics)) {
        const presetElement = document.getElementById("transpose-preset")
        if(presetElement) {
          presetElement.addEventListener("click", transposePresetKey);
        }
        const tUpElement = document.getElementById("transpose-up")
        if(tUpElement) {
          tUpElement.addEventListener("click", upHalfStep);
        }
        const tDownElement = document.getElementById("transpose-down")
        if(tDownElement) {
          tDownElement.addEventListener("click", downHalfStep);
        }
      }
    }

    useEffect(() => {
      addTransposeListeners()
    }, )

    const getTranspose = (chord) => {
        if (transpose === 0) {
          return chord;
        }

        const keyFromChordRegex = /\[([A-G]b*#*).*?\]/gm;
        const keyMatch = songLyrics.match(keyFromChordRegex);
        const key = keyMatch ? keyMatch[0].replace(keyFromChordRegex, "$1") : "C";
    
        const ogKey = key;
        const newKey = Constants.keys[mod((Constants.keys.indexOf(ogKey) + transpose), 12)];
    
        return chord.replace(Constants.regex.chordCore, (match, chordCore, trailingChars) => {
          // whatever the chord is for original scale, put that chord on the new scale
          // if the scale doesn't make sense, try the bestGuess™ scale
          return (
            Constants.scales[newKey][Constants.scales[ogKey].indexOf(chordCore)] ||
            Constants.bestGuessScale[mod(Constants.bestGuessScale.indexOf(chordCore)+transpose, 12)] ||
            '?'
          ) + trailingChars
        })
      }

    const formatVerseNumbers = (lyrics) => {
      return lyrics.replace(Constants.regex.verseNumber, `$1<div class='verse-number' data-uncopyable-text='$2'></div>`);
    }

    const formatChorus = (lyrics) => {
        return lyrics.replace(Constants.regex.chorus, `$1<div class='chorus'>$2</div>`);
    }

    const formatCapoComment = (line) => {
        return line.replace(Constants.regex.capo, `<div id='transpose-preset'>Capo $1</div>`);
    }

    const formatComment = (line) => {
        return line.replace(Constants.regex.comment, "<div class='comment'>$1</div>");
    }

    const formatComment2 = (line) => {
      return line.replace(Constants.regex.comment2, "<div class='comment2'>$1</div>");
  }

    const formatTextLine = (line) => {
        // Chords have 0 width and double height, so they appear above the text.
        // Both chords and text are in the same "line" block so they are aligned.
        //const lineRegex = /(.*\>)?(  )?(.*)/;
		const lineRegex = /(.*>)?( {2})?(.*)/;
        return line.replace(lineRegex, `$1<div class='line'><span class='line-text'>$3</span></div>`);
    }

    const formatChords = (line) => {
        // words containing chords are in a chord-word span, so that if the line is too long,
        // the text wrapping can move the whole word with chords.
        const showChords = props.showChords
        if (!showChords) {
          const separatedIntoWords = line.replace(Constants.regex.chordWords, `<span class='chord-word-hide'>$1</span>`);
        return separatedIntoWords.replace(Constants.regex.chords, (match, chord) => `<span class='chord-hide' data-uncopyable-text='${getTranspose(chord)}'></span>`);
        }
        const separatedIntoWords = line.replace(Constants.regex.chordWords, `<span class='chord-word'>$1</span>`);
        return separatedIntoWords.replace(Constants.regex.chords, (match, chord) => `<span class='chord' data-uncopyable-text='${getTranspose(chord)}'></span>`);
    }

    const formatTextBoldItalic = (text) => {
        text = text.replace(Constants.regex.boldText, "<b>$1</b>");
        text = text.replace(Constants.regex.italicText, "<i>$1</i>");
        return text
    }

    const formatMusicalTies = (lyrics) => {
        // convert _ to musical tie for spanish songs
        return lyrics.replace(/_/g, "<span class='musical-tie'>‿</span>");
    }

    const BookmarkIconAsString = '--9900--'

    // const relatedSongs = props.relatedSongs?.map((s, i) => (<button>{i}</button>))
    // console.log('the related songs', relatedSongs);

    const controls = () => {
      return `
        <div class='related-songs'>
        </div>
        <div class='song-controls'>
          <div class='bookmark'>
            ${BookmarkIconAsString}
          </div>
          ${transposeControls()}
        </div>
      `
    }

    const transposeControls = () => {
      if (!props.showChords) {
        return ''
      }
      const songHasChords = /\[/.test(songLyrics);
      if(!songHasChords) {
        return '';
      }
  
      return `
      <div class='top-section'>
        <div class='transpose-controls'>
          <a id='transpose-down' class='transpose-symbol'>-</a>
          <div class='transpose-value'>${transpose}</div>
          <a id='transpose-up' class='transpose-symbol'>+</a>
        </div>
      </div>
        `;
    }

    const getLyricsHTML = () => {
        let lyrics = songLyrics;
    
        if (Constants.regex.html_safety.test(lyrics)) {
          return "ERROR: HTML tags are forbidden. Please do not use '<', '>', or backticks.";
        }
    
        lyrics = lyrics.replace(Constants.regex.invisableUnicodeCharacters, "");
        lyrics = formatVerseNumbers(lyrics);
        lyrics = formatChorus(lyrics);
        let lines = lyrics.split('\n')
        
        for (let i = 0; i < lines.length; i++) {
          if (Constants.regex.comment2.test(lines[i])) {
            lines[i] = formatComment2(lines[i]);
          } else if (Constants.regex.capo.test(lines[i])) {
            lines[i] = formatCapoComment(lines[i]);
          } else if (Constants.regex.comment.test(lines[i])) {
            lines[i] = formatComment(lines[i]);
          } else {
            lines[i] = formatTextLine(lines[i]);
    
            // if (Constants.regex.hasChords.test(lines[i]) && props.showChords) {
            //   lines[i] = formatChords(lines[i]);
            // }
            if (Constants.regex.hasChords.test(lines[i])) {
              lines[i] = formatChords(lines[i]);
            }
          }
        }
        lines.unshift(controls());
        lyrics = lines.join('\n');
        // lyrics = lines.join("<br />");
        lyrics = formatTextBoldItalic(lyrics);
        lyrics = formatMusicalTies(lyrics);
        return lyrics;
      }

    return songLyrics === undefined ? (<div />) : (
        <div>
            <div
                className="lyrics"
                dangerouslySetInnerHTML={{ __html: getLyricsHTML() }}
            />
        </div>
    )
}

export default SongLyrics