import { Routes, Route } from "react-router-dom";
import HomePage from "../pages/Home";
import SongDetailPage from "../pages/SongDetail";

const Router = props => {
    return(
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/detail/:songId" element={<SongDetailPage />} />
            <Route path="/song/:songId" element={<SongDetailPage />} />
        </Routes>
    )
}

export default Router