import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Loader } from 'rsuite';
import SongListItem from "../components/SongListItem";
import { fetchSongs, searchSongs, clearSongs } from "../store/actions/songs";
import '../stylesheets/styles.css'
import Popup from "reactjs-popup";
import { ReactComponent as SettingsIcon } from '../assets/settings.svg';
import LanguageOptions from "../components/LanguageOptions";
//import { Helmet } from "react-helmet";
import { Helmet } from "react-helmet-async";

const HomePage = () => {
    const {loadingSongs, songs, availableLangs} = useSelector(state => state.songs)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [selectedLangs, setSelectedLangs] = useState([])

	// Set dynamic title & description based on hostname
	const hostname = window.location.hostname;
	let title = "Partaker Songs"; // Default title
	let description = "Discover, search, and enjoy a collection of songs and hymns from the Partaker app.";

	if (hostname === "songs.enjoyer.life") {
		title = "Enjoyer Songs";
		description = "A collection of songs and hymns from the Enjoyer app.";
	} else if (hostname === "songstest.enjoyer.life") {
		title = "Staging - Enjoyer Songs";
		description = "Testing the Enjoyer songs platform.";
	} else if (hostname === "songs.enjoyer.local") {
		title = "Dev - Enjoyer Songs";
		description = "Local development environment for Enjoyer Songs.";
	} else if (hostname === "localhost") {
		title = "Dev - Partaker Songs";
		description = "Local development environment for Partaker Songs.";
	}

    // console.log('Home PAge,', selectedLangs);

	// Memorize fetchData using useCallback
	const fetchData = useCallback((searchQuery) => {
		dispatch(fetchSongs(searchQuery));
	}, [dispatch]); // Only re-create fetchData when dispatch changes

	useEffect(() => {
		fetchData(''); // You can adjust the search query if needed
	}, [fetchData]);  // Now fetchData is stable and included in the dependency array

/*
    const fetchData = (searchQuery) => {
        dispatch(fetchSongs(searchQuery))
    }

    useEffect(() => {
        fetchData('')
    }, [dispatch])
*/
/*
    useEffect(() => {
        fetchData('');
    }, [fetchData, dispatch]);  // Include fetchData and dispatch in the dependency array
*/

    const searchSongHandler = searchKeyword => {
        dispatch(searchSongs(searchKeyword))
    }

    const handleOnClick = songItem => {
        
        // navigate('/detail', {state:{songId: songItem.id}})
        navigate(`/song/${songItem.id}`)
    }

    const updateSelectedLangs = langs => {
        console.log('make updates in selected langs', langs);
        setSelectedLangs(langs)
        let searchquery = ''
        if (langs.length > 0) {
            searchquery += '?langs='
            for(const lang of langs) {
                searchquery += `${lang.code},`
            }
        }
        dispatch(clearSongs())
        fetchData(searchquery)
    }

    const LanguageSettingIcon = (
        <Popup
            trigger={<div><SettingsIcon className="setting-icon" /></div>}
            modal>
            {close => (
                <div className="overlay">
                    <button className="close" onClick={close}>
                        &times;
                    </button>
                    <LanguageOptions
                        availableLangs={availableLangs}
                        selectedLangs={selectedLangs}
                        updateSelectedLangs={updateSelectedLangs}
                        close={close}
                    />
                </div>
            )}
        </Popup>
    );

    const listItems = songs.map((s, i) => (
        <SongListItem
            key={`${s.id}-${s.sort_order}`}
            item={s}
            index={i}
            onClick={handleOnClick.bind(this, s)}
        />
    ));

    return songs.length > 0 || loadingSongs ? (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={window.location.href} />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
            </Helmet>
            <div className="home-div">
                {LanguageSettingIcon}
                <div className="list-body">
                    <h1>{title}</h1>
                    {(loadingSongs && songs.length === 0) ? (
                        <></>
                    ) : (
                        <input
                            className="search-input"
                            placeholder="search here..."
                            onChange={(e) => searchSongHandler(e.target.value)}
                        />
                    )}
                    {(loadingSongs && songs.length === 0) ? (
                        <Loader backdrop content="loading..." vertical />
                    ) : (
                        listItems
                    )}
                </div>
            </div>
        </>
    ) : (
        <div>Loading...</div>
    );
};

export default HomePage